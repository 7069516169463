<template>
  <div class="content-box">
    <div class="content">
      <div class="search-action">
        <div class="inputs_filter">
           <el-input
            placeholder="请输入关键字"
            prefix-icon="el-icon-search"
            class="search-input"
            v-model="search"
            clearable
            @clear="getServerList"
          >
          </el-input>
          <div class="search-btn" @click="getServerList">搜索</div>
        </div>
      </div>

      <div class="recommend">
        <div class="recommend-title">
          <span class="big-title">为你推荐</span>
        </div>
        <div class="recommend-outer">
          <ul class="recommend-content">
            <li
              class="recommend-item"
              v-for="(ie, index) in fwList"
              :key="index"
               @click="xq(ie.id)"
            >
              <div class="r_nav left">
                <div class="t_t">
                  <div class="map-inners-icon">
                    <span class="R_two">{{ ie.title }}</span>
                  </div>
                </div>
                <div class="three">
                  <div class="product">
                    <span v-html="ie.explain"> </span>
                  </div>
                </div>
                <div class="price">
                  <span>{{ ie.cost }}元</span>
                </div>
                <div class="btn_last">
                  <span class="buy-btn">立即购买</span>
                  <span class="contact-btn"
                    ><i class="iconfont"></i>立即咨询</span
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>
        <!-- 分页 -->
        <div class="pagination">
          <pagination
            class="pages"
            v-show="total > 0"
            :total="total"
            layout="prev, pager, next"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.limit"
            @pagination="getServerList"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ServerList } from "../../../common/js/api";
import Pagination from "@/common/Pagination";

export default {
  components: { Pagination },
  data() {
    return {
      fwList: [],
      total: 0, //总条目数
      listQuery: {
        limit: 9,
        page: 1,
      },
      search:''
    };
  },
  created() {
    this.getServerList();
  },
  methods: {
    // 获取服务综合展示列表
    getServerList() {
      ServerList({
        title:this.search,
        page: this.listQuery.page,
        limits: this.listQuery.limit,
      })
        .then((res) => {
          console.log(res);
          this.fwList = res.data.data;
          this.total = res.data.total;
        })
        .catch(() => {});
    },
    // 进入详情页
    xq(id) {
        let routeData = this.$router.resolve({
        path: "/s_xq",
         query: {
          id: id,
        },
      });
       window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  .search-action {
        margin: 30px 30% 16px 20%;
  }
  /deep/.el-input__inner {
    border: 0 ;
}
  .recommend {
    .recommend-outer {
      padding-top: 10px;
      .recommend-content {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;
        .recommend-item:nth-child(3n + 0) {
          margin-right: 0;
        }
        .recommend-item {
          margin-top: 20px;
          width: calc(33.3% - 15px);
          height: 190px;
          margin-right: 20px;
          background: #fff;
          border-radius: 6px;
          //  margin-bottom: 20px;
          cursor: pointer;
          font-size: 14px;
          .r_nav {
            width: 100%;
            height: 100%;
            padding: 15px 8px 10px 27px;
            margin-right: 0;
            .R_two {
              font-size: 18px;
            }
            .three {
              height: 46px;
              color: #696969;
               .product {
                 height: 40px;
                 text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
      white-space: pre-wrap;
               }
            }
            .btn_last {
              margin-top: 20px;
            }
            .buy-btn {
              padding: 7px 14px;
            }
          }
        }
      }
    }
    .pagination {
      text-align: center;
    }
  }
}
</style>